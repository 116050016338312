import React, { useEffect, useRef, useState } from "react";
import "./home.css";
import logo from "./assets/logo.svg"
import honesti from "./assets/honesti.svg"
import image1 from "./assets/banner1.svg"
import image2 from "./assets/banner2.svg"
import image3 from "./assets/banner3.svg"
import image4 from "./assets/banner4.svg"
import rightImage from "./assets/left.svg"
import leftImage from "./assets/right.svg"
import solution1 from "./assets/1.svg"
import solution2 from "./assets/2.svg"
import solution3 from "./assets/3.svg"
import solution4 from "./assets/4.svg"
import solution5 from "./assets/5.svg"
import solution6 from "./assets/6.svg"
import solution7 from "./assets/7.svg"
import bottomImage from "./assets/bottomImage.svg"
import youtube from "./assets/youtube1.svg"
import twitter from "./assets/twitter1.svg"
import fb from "./assets/fb.svg"
import lin from "./assets/lin.svg"
import instagram from "./assets/instagram.svg"
import whatsapp from "./assets/whatsapp1.svg"
import software1 from "./assets/8.svg"
import software2 from "./assets/9.svg"
import software3 from "./assets/10.svg"
import software4 from "./assets/11.svg"
import software5 from "./assets/12.svg"
import chart from "./assets/chart.svg"
import mbanner1 from "./assets/sample1.svg"
import mbanner2 from "./assets/m2.svg"
import mbanner3 from "./assets/m3.svg"
import mbanner4 from "./assets/m4.svg"
import mbottomImage from "./assets/mbottom.svg"
import { Button, Carousel, Drawer, Dropdown, Input, Menu, message, Select, Space } from "antd";
import type { MenuProps } from 'antd';
import { DownOutlined, CheckOutlined, ArrowRightOutlined, AlignRightOutlined } from '@ant-design/icons';
import axios from "axios";
import footerTitle from "./assets/footerTitle.svg"
const text = [
    "Expand ",
    "Resolve ",
    "Streamline "
]



const solutionOptions = [
    "Website Development",
    "Mobile App Development",
    "Digital Marketing",
    "Post Editing",
    "Whatsapp API",
    "Video Editing",
    "E-Commerce Service"
]

const softwareOptions = [
    "Honesti CRM",
    "Whatsapp Bulk Sender",
    "Socialmedia Automation Software",
    "Whatsapp API Platform",
    "Sms, Call & Email Broadcast"
]

const countryCodeOptions = [
    { "value": "+91", "label": "+91" },
    { "value": "+1", "label": "+1" },
    { "value": "+20", "label": "+20" },
    { "value": "+211", "label": "+211" },
    { "value": "+212", "label": "+212" },
    { "value": "+213", "label": "+213" },
    { "value": "+216", "label": "+216" },
    { "value": "+218", "label": "+218" },
    { "value": "+220", "label": "+220" },
    { "value": "+221", "label": "+221" },
    { "value": "+222", "label": "+222" },
    { "value": "+223", "label": "+223" },
    { "value": "+224", "label": "+224" },
    { "value": "+225", "label": "+225" },
    { "value": "+226", "label": "+226" },
    { "value": "+227", "label": "+227" },
    { "value": "+228", "label": "+228" },
    { "value": "+229", "label": "+229" },
    { "value": "+230", "label": "+230" },
    { "value": "+231", "label": "+231" },
    { "value": "+232", "label": "+232" },
    { "value": "+233", "label": "+233" },
    { "value": "+234", "label": "+234" },
    { "value": "+235", "label": "+235" },
    { "value": "+236", "label": "+236" },
    { "value": "+237", "label": "+237" },
    { "value": "+238", "label": "+238" },
    { "value": "+239", "label": "+239" },
    { "value": "+240", "label": "+240" },
    { "value": "+241", "label": "+241" },
    { "value": "+242", "label": "+242" },
    { "value": "+243", "label": "+243" },
    { "value": "+244", "label": "+244" },
    { "value": "+245", "label": "+245" },
    { "value": "+246", "label": "+246" },
    { "value": "+248", "label": "+248" },
    { "value": "+249", "label": "+249" },
    { "value": "+250", "label": "+250" },
    { "value": "+251", "label": "+251" },
    { "value": "+252", "label": "+252" },
    { "value": "+253", "label": "+253" },
    { "value": "+254", "label": "+254" },
    { "value": "+255", "label": "+255" },
    { "value": "+256", "label": "+256" },
    { "value": "+257", "label": "+257" },
    { "value": "+258", "label": "+258" },
    { "value": "+260", "label": "+260" },
    { "value": "+261", "label": "+261" },
    { "value": "+262", "label": "+262" },
    { "value": "+263", "label": "+263" },
    { "value": "+264", "label": "+264" },
    { "value": "+265", "label": "+265" },
    { "value": "+266", "label": "+266" },
    { "value": "+267", "label": "+267" },
    { "value": "+268", "label": "+268" },
    { "value": "+269", "label": "+269" },
    { "value": "+27", "label": "+27" },
    { "value": "+290", "label": "+290" },
    { "value": "+291", "label": "+291" },
    { "value": "+297", "label": "+297" },
    { "value": "+298", "label": "+298" },
    { "value": "+299", "label": "+299" },
    { "value": "+30", "label": "+30" },
    { "value": "+31", "label": "+31" },
    { "value": "+32", "label": "+32" },
    { "value": "+33", "label": "+33" },
    { "value": "+34", "label": "+34" },
    { "value": "+350", "label": "+350" },
    { "value": "+351", "label": "+351" },
    { "value": "+352", "label": "+352" },
    { "value": "+353", "label": "+353" },
    { "value": "+354", "label": "+354" },
    { "value": "+355", "label": "+355" },
    { "value": "+356", "label": "+356" },
    { "value": "+357", "label": "+357" },
    { "value": "+358", "label": "+358" },
    { "value": "+359", "label": "+359" },
    { "value": "+36", "label": "+36" },
    { "value": "+370", "label": "+370" },
    { "value": "+371", "label": "+371" },
    { "value": "+372", "label": "+372" },
    { "value": "+373", "label": "+373" },
    { "value": "+374", "label": "+374" },
    { "value": "+375", "label": "+375" },
    { "value": "+376", "label": "+376" },
    { "value": "+377", "label": "+377" },
    { "value": "+378", "label": "+378" },
    { "value": "+379", "label": "+379" },
    { "value": "+380", "label": "+380" },
    { "value": "+381", "label": "+381" },
    { "value": "+382", "label": "+382" },
    { "value": "+383", "label": "+383" },
    { "value": "+385", "label": "+385" },
    { "value": "+386", "label": "+386" },
    { "value": "+387", "label": "+387" },
    { "value": "+389", "label": "+389" },
    { "value": "+39", "label": "+39" },
    { "value": "+40", "label": "+40" },
    { "value": "+41", "label": "+41" },
    { "value": "+420", "label": "+420" },
    { "value": "+421", "label": "+421" },
    { "value": "+423", "label": "+423" },
    { "value": "+43", "label": "+43" },
    { "value": "+44", "label": "+44" },
    { "value": "+45", "label": "+45" },
    { "value": "+46", "label": "+46" },
    { "value": "+47", "label": "+47" },
    { "value": "+48", "label": "+48" },
    { "value": "+49", "label": "+49" },
    { "value": "+500", "label": "+500" },
    { "value": "+501", "label": "+501" },
    { "value": "+502", "label": "+502" },
    { "value": "+503", "label": "+503" },
    { "value": "+504", "label": "+504" },
    { "value": "+505", "label": "+505" },
    { "value": "+506", "label": "+506" },
    { "value": "+507", "label": "+507" },
    { "value": "+508", "label": "+508" },
    { "value": "+509", "label": "+509" },
    { "value": "+51", "label": "+51" },
    { "value": "+52", "label": "+52" },
    { "value": "+54", "label": "+54" },
    { "value": "+55", "label": "+55" },
    { "value": "+56", "label": "+56" },
    { "value": "+57", "label": "+57" },
    { "value": "+58", "label": "+58" },
    { "value": "+590", "label": "+590" },
    { "value": "+591", "label": "+591" },
    { "value": "+592", "label": "+592" },
    { "value": "+593", "label": "+593" },
    { "value": "+595", "label": "+595" },
    { "value": "+597", "label": "+597" },
    { "value": "+598", "label": "+598" },
    { "value": "+599", "label": "+599" },
    { "value": "+60", "label": "+60" },
    { "value": "+61", "label": "+61" },
    { "value": "+62", "label": "+62" },
    { "value": "+63", "label": "+63" },
    { "value": "+64", "label": "+64" },
    { "value": "+65", "label": "+65" },
    { "value": "+66", "label": "+66" },
    { "value": "+670", "label": "+670" },
    { "value": "+673", "label": "+673" },
    { "value": "+674", "label": "+674" },
    { "value": "+675", "label": "+675" },
    { "value": "+676", "label": "+676" },
    { "value": "+677", "label": "+677" },
    { "value": "+678", "label": "+678" },
    { "value": "+679", "label": "+679" },
    { "value": "+680", "label": "+680" },
    { "value": "+681", "label": "+681" },
    { "value": "+682", "label": "+682" },
    { "value": "+683", "label": "+683" },
    { "value": "+685", "label": "+685" },
    { "value": "+686", "label": "+686" },
    { "value": "+687", "label": "+687" },
    { "value": "+688", "label": "+688" },
    { "value": "+689", "label": "+689" },
    { "value": "+690", "label": "+690" },
    { "value": "+691", "label": "+691" },
    { "value": "+692", "label": "+692" },
    { "value": "+7", "label": "+7" },
    { "value": "+81", "label": "+81" },
    { "value": "+82", "label": "+82" },
    { "value": "+84", "label": "+84" }
];

const industryOptions = [
    { "value": "Advertising, Media & Marketing Services", "label": "Advertising, Media & Marketing Services" },
    { "value": "Arts & Crafts", "label": "Arts & Crafts" },
    { "value": "Automobile & Auto Parts", "label": "Automobile & Auto Parts" },
    { "value": "Baby & Children Products", "label": "Baby & Children Products" },
    { "value": "Beauty & Personal Care", "label": "Beauty & Personal Care" },
    { "value": "Business & Enterprise", "label": "Business & Enterprise" },
    { "value": "Clothing & Apparel", "label": "Clothing & Apparel" },
    { "value": "E-commerce Marketplace", "label": "E-commerce Marketplace" },
    { "value": "Education & Training", "label": "Education & Training" },
    { "value": "Electronics & Appliances", "label": "Electronics & Appliances" },
    { "value": "Entertainment", "label": "Entertainment" },
    { "value": "Event Planning & Management", "label": "Event Planning & Management" },
    { "value": "Fashion Accessories", "label": "Fashion Accessories" },
    { "value": "Food & Beverages", "label": "Food & Beverages" },
    { "value": "Gems & Jewellery", "label": "Gems & Jewellery" },
    { "value": "Gifting Items", "label": "Gifting Items" },
    { "value": "Groceries & Convenience", "label": "Groceries & Convenience" },
    { "value": "Herbal & Ayurvedic Products", "label": "Herbal & Ayurvedic Products" },
    { "value": "Home & Office Furnishing", "label": "Home & Office Furnishing" },
    { "value": "Hotels, Clubs, Restaurants & Hospitality", "label": "Hotels, Clubs, Restaurants & Hospitality" },
    { "value": "HR & Recruitment Services", "label": "HR & Recruitment Services" },
    { "value": "Information Technology", "label": "Information Technology" },
    { "value": "Insurance, Finance & Banking", "label": "Insurance, Finance & Banking" },
    { "value": "Manufacturing & Industrial Products", "label": "Manufacturing & Industrial Products" },
    { "value": "Medical & Healthcare", "label": "Medical & Healthcare" },
    { "value": "NGO & Non-Profit Organisation", "label": "NGO & Non-Profit Organisation" },
    { "value": "Property & Real Estate", "label": "Property & Real Estate" },
    { "value": "Shopping & Retail", "label": "Shopping & Retail" },
    { "value": "Sports & Fitness", "label": "Sports & Fitness" },
    { "value": "Travel & Tourism", "label": "Travel & Tourism" },
    { "value": "Others", "label": "Others" }
];
const initialErrorList = {
    firstName: "",
    lastName: "",
    mobile: "",
    email: ""
}

const inputInitialVales = {
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    countryCode: "+91",
    companyName: "",
    website: "",
    location: "",
    designation: "",
    industry: "",
    yearStarted: ""
}


export const Home = () => {
    const [randomText, setRandomText] = useState("Expand")
    const [yearStartedOptions, setYearStartedOptions] = useState<any>([])
    const [openDrawer, setOpenDrawer] = useState(false)
    const solutionRef_0 = useRef<HTMLDivElement>(null);
    const solutionRef_1 = useRef<HTMLDivElement>(null);
    const solutionRef_2 = useRef<HTMLDivElement>(null);
    const solutionRef_3 = useRef<HTMLDivElement>(null);
    const solutionRef_4 = useRef<HTMLDivElement>(null);
    const solutionRef_5 = useRef<HTMLDivElement>(null);
    const solutionRef_6 = useRef<HTMLDivElement>(null);
    const softwareRef_0 = useRef<HTMLDivElement>(null);
    const softwareRef_1 = useRef<HTMLDivElement>(null);
    const softwareRef_2 = useRef<HTMLDivElement>(null);
    const softwareRef_3 = useRef<HTMLDivElement>(null);
    const softwareRef_4 = useRef<HTMLDivElement>(null);
    const formRef_0 = useRef<HTMLDivElement>(null);
    const contactRef_1 = useRef<HTMLDivElement>(null);
    const box_1 = useRef<HTMLDivElement>(null);
    const solutionRef = useRef<HTMLDivElement>(null);
    const softwareRef = useRef<HTMLDivElement>(null);
    const [errorList, setErrorList] = useState(initialErrorList)
    const [inputReq, setInputReq] = useState(inputInitialVales)


    useEffect(() => {
        const setRandomString = () => {
            const randomIndex = Math.floor(Math.random() * text.length);
            setRandomText(text[randomIndex]);
        };

        const intervalId = setInterval(setRandomString, 3000);

        return () => clearInterval(intervalId);
    }, []);
    useEffect(() => {
        const element = document.getElementById('typewriterText');
        element?.classList.remove('typewriter');
        void element?.offsetWidth; // Trigger reflow
        element?.classList.add('typewriter');
    }, [randomText]);

    const refsMap = {
        solutionRef_0,
        solutionRef_1,
        solutionRef_2,
        solutionRef_3,
        solutionRef_4,
        solutionRef_5,
        solutionRef_6,
        softwareRef_0,
        softwareRef_1,
        softwareRef_2,
        softwareRef_3,
        softwareRef_4,
        formRef_0,
        contactRef_1,
        solutionRef,
        softwareRef,
        box_1
    };

    const solutionItems: MenuProps['items'] = solutionOptions.map((val: string, index: number) => {
        return {
            key: index,
            label: <div onClick={() => {
                setOpenDrawer(false)
                scrollToDiv(`solutionRef_${index}`)
            }}>{val}</div>
        }
    })
    const softwareItems: MenuProps['items'] = softwareOptions.map((val: string, index: number) => {
        return {
            key: index,
            label: <div onClick={() => {
                setOpenDrawer(false);
                scrollToDiv(`softwareRef_${index}`)
            }}>{val}</div>

        }
    })

    const solutionMenu = <Menu items={solutionItems} />;
    const softwareMenu = <Menu items={softwareItems} />;
    useEffect(() => {
        const years = [];
        for (let year = 1950; year <= 2024; year++) {
            years.push({
                value: String(year),
                label: String(year),
            });
        }
        setYearStartedOptions(years)
    }, [])

    const scrollToDiv = (divRefName: string) => {
        const divRef = refsMap[divRefName as keyof typeof refsMap];
        if (divRef?.current) {
            const headerOffset = 100; // Replace with your header height
            const elementPosition = divRef.current.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    const handleSubmit = async () => {
        var errors = initialErrorList
        var isErrorsFound = false
        if (inputReq.firstName === "") {
            errors.firstName = "This field can't be empty"
            isErrorsFound = true
        } if (inputReq.lastName === "") {
            errors.lastName = "This field can't be empty"
            isErrorsFound = true
        } if (inputReq.mobile === "") {
            errors.mobile = "This field can't be empty"
            isErrorsFound = true
        } if (inputReq.email === "") {
            errors.email = "This field can't be empty"
            isErrorsFound = true
        } else if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputReq.email) === false) {
            errors.email = "Please enter valid email"
            isErrorsFound = true
        }
        console.log(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputReq.email))
        if (isErrorsFound) {
            setErrorList(errors)
            message.error("Invalid request, kindly check the inputs")
            return
        }
        try {
            const data = { ...inputReq, "createdDate": new Date() }
            const resp = await axios.post("https://hooks.honesti.in/webhook/5e04c91d-4570-42a5-a37f-b7cc7e67780e", data)
            if (resp) {
                message.success("Your request has been submitted successfully")
                setInputReq(inputInitialVales)
            }
        } catch (e) {
            console.log(e)
            message.error("Form submission failed,try again later")
        }

    }

    const handleChangeValues = (e: any, key: string) => {
        setErrorList({ ...errorList, [key]: "" })
        setInputReq({ ...inputReq, [key]: e.target.value })
    }

    return (

        // <div id="typewriterText" className="typewriter">
        //     <h1 >{randomText}</h1>
        // </div>
        <div className="container">
            <div className="header">
                <div className="first-part">
                    <div style={{ display: "flex", alignItems: "center" }}><img alt="logo" className="logo" src={logo} />
                        <img alt="title" className="logo-title" src={honesti} /></div>
                    {window.innerWidth > 800 ? <><div className="knowUs" onClick={() => scrollToDiv("box_1")}>Know us</div>
                        <Dropdown className="getSolutions" overlay={solutionMenu} placement="bottom" arrow >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ marginRight: "10px" }}>Get Solutions</span>
                                <DownOutlined />
                            </div>
                        </Dropdown>
                        <Dropdown className="getSolutions" overlay={softwareMenu} placement="bottom" arrow >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ marginRight: "10px" }}>Use Softwares</span>
                                <DownOutlined />
                            </div>
                        </Dropdown></>
                        : <div><AlignRightOutlined onClick={() => setOpenDrawer(true)} /></div>
                    }

                </div>
                <div className="second-part">
                    {/* <Popover content="Select Language" > */}
                    <Select title="Select Language" className="lang" style={{ borderRadius: "16px" }} defaultValue="en">
                        <Select.Option value="en">
                            <div style={{ display: "flex" }}>
                                <div style={{ marginRight: "20px" }}>en</div>
                                <CheckOutlined />
                            </div>
                        </Select.Option>
                    </Select>
                    {/* </Popover> */}
                    <Button style={{ marginLeft: "20px", marginRight: "20px" }} className="contact" onClick={() => scrollToDiv("contactRef_1")}>Contact</Button>
                    <Button className="getStarted" onClick={() => scrollToDiv("formRef_0")}>Get Started</Button>
                </div>
            </div>
            <div className="content">
                <Carousel speed={200} autoplay>
                    <div >
                        <img alt="banner1" className="contentStyle" src={window.innerWidth > 800 ? image1 : mbanner1} loading="lazy" />
                        <div className="image-content">
                            <div className="title">Step Into the Digital World with Confidence</div>
                            <div className="desc">We Simplify Technology, So You Can Focus on What You Do Best.</div>
                            <Button className="image-button" onClick={() => scrollToDiv("solutionRef")}>Grow Today</Button>
                        </div>
                    </div>
                    <div  >
                        <img alt="banner2" className="contentStyle" src={window.innerWidth > 800 ? image2 : mbanner2} />
                        <div className="image-content">
                            <div className="title">Experience the Future of Digital Business Solutions</div>
                            <div className="desc">Empower Your Business with Seamless and Efficient Digital Transformation</div>
                            <Button className="image-button" onClick={() => scrollToDiv("solutionRef")}>Get Solutions</Button>
                        </div>
                    </div>
                    <div >
                        <img alt="banner3" className="contentStyle" src={window.innerWidth > 800 ? image3 : mbanner3} />
                        <div className="image-content">
                            <div className="title">Unlock Your Business Potential</div>
                            <div className="desc">Easy-to-use software that supports your growth.</div>
                            <Button className="image-button" onClick={() => scrollToDiv("softwareRef")}>Try Software</Button>
                        </div>
                    </div>
                    <div >
                        <img alt="banner4" className="contentStyle" src={window.innerWidth > 800 ? image4 : mbanner4} />
                        <div className="image-content">
                            <div className="title">No Matter Who You Are, We’re Here to Help Your Business Grow</div>
                            <div className="desc">Our Solutions Are Designed to Support All Businesses.</div>
                            <Button className="image-button" onClick={() => scrollToDiv("contactRef_1")}>Contact Now</Button>
                        </div>
                    </div>
                </Carousel>
                <div className="wrapper">
                    <div className="white-box" ref={box_1}>
                        {/* <div className="title">Elevate Your Business: Grow Digitally</div>
                        <div className="sub-title">Empowering businesses with innovative digital solutions for unparalleled growth.</div> */}
                        <div className="desc">
                            <span id="typewriterText" className="typewriter">
                                <h1 style={{ borderRight: "2px solid red", color: (randomText === "resolved" ? "#002454" : "#C400FF") }}>{randomText}</h1>
                            </span>
                            <span style={{ color: "#002454", fontSize: window.innerWidth > 800 ? "60px" : "24px" }}>  Your Business Digitally</span>
                        </div>
                        <img alt="chart" src={chart} className="rotate" style={{ width: "100%" }} />

                    </div>
                    <div className="split-box">
                        <div className="first-part">
                            <div className="text-part">
                                <div className="title">Contact us today and ignite your business growth!</div>
                                <Button
                                    className="contact-button"
                                    type="primary"
                                    icon={<ArrowRightOutlined />}
                                    iconPosition="end"
                                    onClick={() => scrollToDiv("formRef_0")}
                                >
                                    Contact Us
                                </Button>
                            </div>
                            <img alt="leftImage" style={{ height: "100%", width: "45%" }} src={leftImage} />
                        </div>
                        <div className="second-part">
                            <div className="text-part">
                                <div className="title">Reach New Customers and Boost Your Sales!</div>
                                <Button
                                    className="contact-button"
                                    type="primary"
                                    icon={<ArrowRightOutlined />}
                                    iconPosition="end"
                                    onClick={() => scrollToDiv("solutionRef")}

                                >
                                    Explore Services
                                </Button>
                            </div>
                            <img alt="rightImage" style={{ height: "100%", width: "45%" }} src={rightImage} />
                        </div>
                    </div>
                    <div className="heading" ref={solutionRef}>Business Growth-Enabling <span style={{ color: "#C400FF" }}>Solutions</span></div>
                    <div className="cards-section">
                        <div className="card" ref={solutionRef_0}>
                            {window.innerWidth > 800 && <img alt="solution1" className="card-image" src={solution1} />}
                            <div className="card-text">
                                <div className="heading" style={{ color: "#C400FF" }}>Website Development</div>
                                {window.innerWidth <= 800 && <img alt="solution1" className="card-image" src={solution1} />}
                                <div className="title">Modern design, seamless functionality</div>
                                <div className="sub-title">We help businesses thrive online by building beautiful, user-friendly websites that convert visitors
                                    into loyal customers.</div>
                            </div>

                        </div>
                        <div className="card" ref={solutionRef_1}>
                            <div className="card-text">
                                <div className="heading" style={{ color: "#002454" }}>Mobile App Development</div>
                                {window.innerWidth <= 800 && <img alt="solution2" className="card-image" src={solution2} />}
                                <div className="title">Innovate, Develop, Succeed</div>
                                <div className="sub-title">We bridge the gap between your mobile app idea and its successful launch. We collaborate with
                                    businesses to develop user-friendly apps that make a real impact.</div>
                            </div>
                            {window.innerWidth > 800 && <img alt="solution2" className="card-image" src={solution2} />}
                        </div>
                        <div className="card" ref={solutionRef_2}>
                            {window.innerWidth > 800 && <img alt="solution3" className="card-image" src={solution3} />}
                            <div className="card-text">
                                <div className="heading" style={{ color: "#C400FF" }}>Digital Marketing</div>
                                {window.innerWidth <= 800 && <img alt="solution3" className="card-image" src={solution3} />}
                                <div className="title">Targeted Reach, Measurable Impact</div>
                                <div className="sub-title">We help businesses amplify their online presence. We develop data-driven digital marketing
                                    strategies that attract new customers and drive business growth.</div>
                            </div>

                        </div>
                        <div className="card" ref={solutionRef_3}>
                            <div className="card-text">
                                <div className="heading" style={{ color: "#002454" }}>Post Editing</div>
                                {window.innerWidth <= 800 && <img alt="solution4" className="card-image" src={solution4} />}
                                <div className="title">Level Up Your Content</div>
                                <div className="sub-title">We take the stress out of social media. We edit your content to perfection, ensuring your brand voice
                                    shines through and resonates with your target audience.</div>
                            </div>
                            {window.innerWidth > 800 && <img alt="solution4" className="card-image" src={solution4} />}
                        </div>
                        <div className="card" ref={solutionRef_4}>
                            {window.innerWidth > 800 && <img alt="solution5" className="card-image" src={solution5} />}
                            <div className="card-text">
                                <div className="heading" style={{ color: "#C400FF" }}>Whatsapp API</div>
                                {window.innerWidth <= 800 && <img alt="solution5" className="card-image" src={solution5} />}
                                <div className="title">Grow Business With Whatsapp</div>
                                <div className="sub-title">We unlock the power of WhatsApp for your business. We help you integrate the WhatsApp API to
                                    improve customer communication, drive sales, and build stronger relationships.</div>
                            </div>


                        </div>
                        <div className="card" ref={solutionRef_5}>
                            <div className="card-text">
                                <div className="heading" style={{ color: "#002454" }}>Video Editing</div>
                                {window.innerWidth <= 800 && <img alt="solution6" className="card-image" src={solution6} />}
                                <div className="title">Seamless Edits, Stunning Results</div>
                                <div className="sub-title">Stop struggling with editing – start captivating your audience! We offer professional video editing services for social media, helping you create stunning content that gets results.
                                    We handle the editing, so you can focus on building your brand.</div>
                            </div>
                            {window.innerWidth > 800 && <img alt="solution6" className="card-image" src={solution6} />}
                        </div>
                        <div className="card" ref={solutionRef_6}>
                            {window.innerWidth > 800 && <img alt="solution7" className="card-image" src={solution7} />}
                            <div className="card-text">
                                <div className="heading" style={{ color: "#C400FF" }}>E-Commerce Service</div>
                                {window.innerWidth <= 800 && <img alt="solution7" className="card-image" src={solution7} />}
                                <div className="title">Sell Smart, Grow Smarter</div>
                                <div className="sub-title">We become your e-commerce listing experts. We help you navigate the complexities of listing on
                                    major platforms like Amazon, Flipkart,
                                    Meesho,OLX, Alibaba, IndiaMART and Justdial, ensuring your products stand out and reach their ideal
                                    audience.</div>
                            </div>

                        </div>

                    </div>
                    <div className="heading" ref={softwareRef} >Business Growth-Accelerating <span style={{ color: "#C400FF" }}> Software</span></div>
                    <div className="cards-section">
                        <div className="card" ref={softwareRef_0}>
                            <div className="card-text">
                                <div className="heading" style={{ color: "#C400FF" }}>Honesti CRM</div>
                                {window.innerWidth <= 800 && <img alt="software1" className="card-image" src={software1} />}
                                <div className="title">Optimize Engagement, Maximize Success</div>
                                <div className="sub-title">Say goodbye to manual work. Our CRM automates and connects almost everything! Manage social
                                    media interactions (Facebook, Instagram, Twitter), messaging (WhatsApp, SMS), e-commerce
                                    platforms (IndiaMART, Justdial), email, WooCommerce, and Shopify—all in one central hub. We
                                    empower you to build stronger customer relationships with a complete picture</div>
                            </div>
                            {window.innerWidth > 800 && <img alt="software1" className="card-image" src={software1} />}
                        </div>
                        <div className="card" ref={softwareRef_1}>
                            {window.innerWidth > 800 && <img alt="software2" className="card-image" src={software2} />}
                            <div className="card-text">
                                <div className="heading" style={{ color: "#002454" }}>Whatsapp Bulk Sender</div>
                                {window.innerWidth <= 800 && <img alt="software2" className="card-image" src={software2} />}
                                <div className="title">Bulk Send with Precision and Speed</div>
                                <div className="sub-title">We empower businesses to connect with their customers on WhatsApp at scale. Our bulk messaging
                                    software allows for targeted campaigns, personalization, and insightful analytics, helping you build
                                    stronger relationships and drive business growth.</div>
                            </div>

                        </div>
                        <div className="card" ref={softwareRef_2}>

                            <div className="card-text">
                                <div className="heading" style={{ color: "#C400FF" }}>Socialmedia Automation Software</div>
                                {window.innerWidth <= 800 && <img alt="software3" className="card-image" src={software3} />}
                                <div className="title">Automate, Connect, Grow</div>
                                <div className="sub-title">We become your social media sidekick. Our automation software takes care of scheduling and
                                    posting, so you can focus on creating engaging content and interacting with your audience.</div>
                            </div>
                            {window.innerWidth > 800 && <img alt="software3" className="card-image" src={software3} />}
                        </div>
                        <div className="card" ref={softwareRef_3}>
                            {window.innerWidth > 800 && <img alt="software4" className="card-image" src={software4} />}
                            <div className="card-text">
                                <div className="heading" style={{ color: "#002454" }}>Whatsapp API Platform</div>
                                {window.innerWidth <= 800 && <img alt="software4" className="card-image" src={software4} />}
                                <div className="title">Automate WhatsApp, Accelerate Growth</div>
                                <div className="sub-title">We transform customer experiences with WhatsApp. Our API platform empowers you to send rich
                                    media messages, personalize interactions based on customer behavior, and deliver exceptional
                                    support, all within the familiar environment of WhatsApp.</div>
                            </div>

                        </div>
                        <div className="card" ref={softwareRef_4}>

                            <div className="card-text">
                                <div className="heading" style={{ color: "#C400FF" }}>Sms, Call & Email Broadcast</div>
                                {window.innerWidth <= 800 && <img alt="software5" className="card-image" src={software5} />}
                                <div className="title">Effortless Broadcast, Effective Results</div>
                                <div className="sub-title">We streamline communication and simplify outreach. Our platform lets you manage SMS, calls, and
                                    email campaigns effortlessly. We help you craft personalized messages, schedule broadcasts, and
                                    track results, all in one place.</div>
                            </div>
                            {window.innerWidth > 800 && <img alt="software5" className="card-image" src={software5} />}

                        </div>

                    </div>
                    <div className="bottom-part">
                        <img alt="techImage" style={{ height: window.innerWidth > 800 ? 500 : 232, width: "100%", objectFit: "cover" }} src={window.innerWidth > 800 ? bottomImage : mbottomImage} />
                        <div className="tech-text">ALL TECHNOLOGY TO ALL</div>
                    </div>
                    <div className="form-section" ref={formRef_0}>
                        <div className="form-title">We're Here to Help</div>
                        <div className="form-sub-title">Contact Details</div>
                        <div className="field-section">
                            <div style={{ width: "48%" }} >
                                <Input className="input-field" placeholder="First Name" value={inputReq.firstName} onChange={(e) => handleChangeValues(e, "firstName")} />
                                {errorList.firstName && <div style={{ color: "red", fontSize: "14px", textAlign: "left" }}>{errorList.firstName}</div>}
                            </div>
                            <div style={{ width: "48%" }}>
                                <Input className="input-field" placeholder="Last Name" value={inputReq.lastName} onChange={(e) => handleChangeValues(e, "lastName")} />
                                {errorList.lastName && <div style={{ color: "red", fontSize: "14px", textAlign: "left" }}>{errorList.lastName}</div>}
                            </div>
                        </div>
                        <div className="field-section">
                            <div style={{ width: "48%" }}>
                                <Space.Compact style={{ height: "50px", width: "100%" }} className="select-field" >
                                    <Select showSearch optionFilterProp="label"
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        } style={{ height: "50px" }} defaultValue="+91" options={countryCodeOptions} onSelect={(val) => setInputReq({ ...inputReq, "countryCode": val })} />
                                    <Input style={{ backgroundColor: "#c4c4c433" }} placeholder="Personal Whatsapp Number" value={inputReq.mobile} onChange={(e) => handleChangeValues(e, "mobile")} />
                                </Space.Compact>
                                {errorList.mobile && <div style={{ color: "red", fontSize: "14px", textAlign: "left" }}>{errorList.mobile}</div>}
                            </div>
                            <div style={{ width: "48%" }}>
                                <Input className="input-field" placeholder="Work Email" value={inputReq.email} onChange={(e) => handleChangeValues(e, "email")} />
                                {errorList.email && <div style={{ color: "red", fontSize: "14px", textAlign: "left" }}>{errorList.email}</div>}
                            </div>
                        </div>
                        <div className="form-sub-title">Company Details</div>
                        <div className="field-section">
                            <Input className="input-field" style={{ width: "48%" }} placeholder="Company Name" value={inputReq.companyName} onChange={(e) => handleChangeValues(e, "companyName")} />
                            <Input className="input-field" style={{ width: "48%" }} placeholder="Website" value={inputReq.website} onChange={(e) => handleChangeValues(e, "website")} />
                        </div>
                        <div className="field-section">
                            <Input className="input-field" style={{ width: "48%" }} placeholder="Location" value={inputReq.location} onChange={(e) => handleChangeValues(e, "location")} />
                            <Input className="input-field" style={{ width: "48%" }} placeholder="Designation" value={inputReq.designation} onChange={(e) => handleChangeValues(e, "designation")} />
                        </div>
                        <div className="field-section">
                            <Select showSearch optionFilterProp="label"
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                } className="select-field" style={{ width: "48%" }} options={industryOptions} placeholder="Industry" onSelect={(val) => setInputReq({ ...inputReq, industry: val })} />
                            <Select
                                showSearch
                                optionFilterProp="label"
                                filterSort={(optionA, optionB) =>
                                    String(optionA?.label ?? '').toLowerCase().localeCompare(String(optionB?.label ?? '').toLowerCase())
                                }
                                className="select-field"
                                style={{ width: "48%" }}
                                options={yearStartedOptions}
                                placeholder="Year started"
                                onSelect={(val) => setInputReq({ ...inputReq, yearStarted: val })}
                            />

                        </div>
                        <Button className="submit-button" onClick={() => handleSubmit()}>SUBMIT</Button>

                    </div>
                    <div className="footer" ref={contactRef_1}>
                        <div className="first-part">
                            <div style={{ display: "flex" }}>
                                <img alt="logo" style={{ width: "50px", height: "50px" }} src={logo} />
                                <img alt="title" style={{ height: "50px" }} src={footerTitle} />
                            </div>
                            {window.innerWidth > 800 && <div className="footer-text">© 2024, Honesti Digital. All Rights Reserved.</div>}

                        </div>
                        <div className="second-part">
                            <a href="tel:8838887194" target="_blank" rel="noopener noreferrer">
                                <div style={{ cursor: "pointer", color: "white" }}>+91 8838887194</div>
                            </a>
                            <div> support@honesti.in</div>
                            <div>No 26, Aadilakshmi Nagar, Lakshmipuram, Kolathur, Chennai- 99</div>
                            {window.innerWidth < 800 && <div className="footer-text">© 2024, Honesti Digital. All Rights Reserved.</div>}
                            <div style={{ marginTop: "30px", marginBottom: "20px" }}>
                                <a href="https://www.facebook.com/profile.php?id=61561747533515" target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer" }} >
                                    <img alt="fb" style={{ height: "30px", marginLeft: "10px" }} src={fb} />
                                </a>

                                <a href="https://www.instagram.com/honesti__digital/" target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer" }}>
                                    <img alt="instagram" style={{ height: "30px", marginLeft: "10px" }} src={instagram} />
                                </a>
                                <a href="https://www.linkedin.com/in/Honesti-digital" target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer" }}>
                                    <img alt="linkedin" style={{ height: "30px", marginLeft: "10px" }} src={lin} />
                                </a>
                                <a href="https://www.youtube.com/channel/UCFrG43Yp-sG1NTTR9njsiDg" target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer" }} >
                                    <img alt="you tube" style={{ height: "30px", marginLeft: "10px" }} src={youtube} />
                                </a>
                                <a href="https://x.com/honestidigital" target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer" }}>
                                    <img alt="twitter" style={{ height: "25px", marginLeft: "10px" }} src={twitter} />
                                </a>

                            </div>
                        </div>
                    </div>
                </div>

            </div >
            <div className="whatsappDiv">
                <a href="https://wa.me/+918838887194" target="_blank" rel="noopener noreferrer" style={{ display: "flex", alignItems: "center", textWrap: "nowrap" }}>
                    <img alt="whatsapp" style={{ width: "52px", height: "47px", marginRight: "10px" }} src={whatsapp} />
                    <div style={{ color: "white" }}>We are here to help</div>
                </a>
            </div>
            <Drawer title={<div><img alt="logo" style={{ width: "50px" }} src={logo} />
                <img alt="menu" style={{ height: "50px" }} src={honesti} /></div>} onClose={() => setOpenDrawer(false)} open={openDrawer}>
                <div style={{ display: "flex", flexDirection: "column", gap: "20px", fontSize: "20px" }}><div className="knowUs" onClick={() => {
                    setOpenDrawer(false);
                    scrollToDiv("box_1")
                }}>Know us</div>
                    <Dropdown className="getSolutions" overlay={solutionMenu} placement="bottom" arrow >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ marginRight: "10px" }}>Get Solutions</span>
                            <DownOutlined />
                        </div>
                    </Dropdown>
                    <Dropdown className="getSolutions" overlay={softwareMenu} placement="bottom" arrow >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ marginRight: "10px" }}>Use Softwares</span>
                            <DownOutlined />
                        </div>
                    </Dropdown></div>
            </Drawer>
        </div >
    )
}